import { createSlice } from '@reduxjs/toolkit';

export const mainStateSlice = createSlice({
  name: 'mainState',
  initialState: {
    enterprise: {
      image: {},
    },
    subscription: {},
    user: {
      image: {},
      user_setting: {},
      saved_searched: [],
    },
    tracker: {},
    darkState: true
  },
  reducers: {
    setEnterpriseAndUser: (state, action) => {
      state.enterprise = action.payload.enterprise;
      state.user = action.payload.user;
    },
    setEnterpriseAndTracker: (state, action) => {
      state.enterprise = action.payload.enterprise;
      state.tracker = action.payload.tracker;
    },
    setEnterpriseImage: (state, action) => {
      state.enterprise.image = action.payload;
    },
    setUserImage: (state, action) => {
      state.user.image = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTracker: (state, action) => {
      state.tracker = action.payload.tracker;
    },
    setUserSetting: (state, action) => {
      state.user.user_setting = action.payload;
    },
    setDarkState: (state, action) => {
      state.darkState = action.payload
    }
  },
});

export const {
  setUserSetting,
  setEnterpriseAndUser,
  setEnterpriseAndTracker,
  setTracker,
  setEnterpriseImage,
  setUserImage,
  setUser,
    setDarkState
} = mainStateSlice.actions;

export default mainStateSlice.reducer;
