import { createSlice } from '@reduxjs/toolkit';

export const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    user: {
      isLoggedIn: false,
    },
    mainSnackbarOpen: false,
    mainSnackbarMessage: '',
    mainSnackbarType: 'success',
    savedSearchObj: {},
  },
  reducers: {
    clearAppUser: (state) => {
      state.user = {
        isLoggedIn: false,
        username: undefined,
        userrole: undefined,
        jwt: undefined,
      };
    },
    setAppUser: (state, action) => {
      state.user = {
        isLoggedIn: true,
        username: action.payload.username,
        email: action.payload.attributes.email,
        email_verified: action.payload.attributes.email_verified,
        userrole: +action.payload.attributes['custom:userrole'],
        companyName: action.payload.attributes['custom:companyname'],
        taxID: action.payload.attributes['custom:taxid'],
        phone_number: action.payload.attributes['phone_number'],
        phone_extension: action.payload.attributes['custom:extension'],
        jwt: action.payload.token,
      };
    },
    openSnackbar: (state, action) => {
      state.mainSnackbarOpen = action.payload.mainSnackbarOpen;
      state.mainSnackbarMessage = action.payload.mainSnackbarMessage;
      state.mainSnackbarType = action.payload.mainSnackbarType;
    },
    closeSnackbar: (state) => {
      state.mainSnackbarOpen = false;
      state.mainSnackbarMessage = '';
      state.mainSnackbarType = 'success';
    },
    setSavedSearchObj: (state, action) => {
      state.savedSearchObj = action.payload;
    },
  },
});

export const { clearAppUser, setAppUser, closeSnackbar, openSnackbar, setSavedSearchObj } = appStateSlice.actions;

export default appStateSlice.reducer;
